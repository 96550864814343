@import '~browser-reset/reset.scss';


body,html  {
    background: #222;
    padding:0;
    margin:0;
    background-image:  linear-gradient(#000000 1.6px, transparent 1.6px), linear-gradient(90deg, #000000 1.6px, transparent 1.6px), linear-gradient(#000000 0.8px, transparent 0.8px), linear-gradient(90deg, #000000 0.8px, #111111 0.8px);
    background-size: 40px 40px, 40px 40px, 8px 8px, 8px 8px;
    background-position: -1.6px -1.6px, -1.6px -1.6px, -0.8px -0.8px, -0.8px -0.8px;
}

div {
    box-sizing: border-box;
}

.showcase {
    min-height: 100vh;
    display:flex;
    justify-content: center;
    align-items: center;

    .showcase-inner {

        .showcase-image {
        
            margin:0;
            padding:0;
            display:block;
        }
    }
}

.resolutions {

    color:#999;
    padding:5px;
    font-size: 10px;
    width:100%;

}

.warning {
    display: flex;
    position: fixed;
    top:0;
    left:0;
    right:0;
    justify-content: center;
    align-items: center;
    background-color:rgba( #5e160f, 0.75 );
    margin:30px;
    border-radius: 15px;;

    .diff {
        font-size:14px;
        color:white;
        padding:15px;
        line-height:180%;
        max-width: 400px;
        text-align:center;
        position:relative;
    }
}
